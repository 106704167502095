import { Button, Image } from '@mantine/core'
import { NavLink } from 'react-router'

type Props = {
	statusCode?: number
	title?: string
	description?: string
	redirect?: string
	buttonText?: string
	stack?: string
}

const ErrorPage = ({
	statusCode = undefined,
	title = 'Some error happened...',
	description = undefined,
	redirect = undefined,
	buttonText = 'OK',
	stack = undefined,
}: Props) => {
	return (
		<div className="w-full h-full flex content-center">
			<div className="grid grid-cols-6 items-center sm:grid-cols-6 md:grid-cols-12">
				<div className="col-span-6">
					<div className="grid justify-items-center md:justify-items-end">
						{statusCode === 404 ? (
							<Image src="/img/404-page-not-found-monochromatic.svg" width="65%" fit="fill" />
						) : (
							<Image src="/img/bug-fixing-monochromatic.svg" width="65%" fit="fill" />
						)}
					</div>
				</div>
				<div className="col-span-6">
					<div className="grid justify-items-center sm:justify-items-center md:justify-items-start md:pl-4">
						{title && <div className="text-xl">{title}</div>}
						{description && <div className="text-lg">{description}</div>}
						{redirect !== undefined && (
							<div className="pt-4">
								<NavLink to={redirect}>
									<Button
										size="md"
										className="bg-theme-500 hover:bg-theme-500 text-black rounded-full"
									>
										{buttonText}
									</Button>
								</NavLink>
							</div>
						)}
						{stack && (
							<div className="pt-4">
								<p>The stack trace is:</p>
								<pre>{stack}</pre>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ErrorPage
